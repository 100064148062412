.header{
    display: flex;
    height: 20vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
    font-size: 3.5rem;
    font-family: "Heaters";

}