.buttons-container ul{
    margin-top: 2rem;
    display: flex;
    font-size: 1.4rem;
    padding: 0;
}

.buttons-container li{
    position: relative;
    display: flex;
    list-style-type: none;
    transition: all 0.3s;
}

.buttons-container a{
    padding: 0.5rem 0.65rem 0.5rem 0.65rem;
}

.buttons-container li:hover{
    cursor: pointer;
    color: white;
}

.buttons-container li:nth-child(3)::after{
  width: 100%;
}


.buttons-container li::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 101%;
    background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac);
    z-index: -1;
    transition: 0.3s ease all;
    transform: scale(0, 1);
    transform-origin: top left;
    transition: transform 500ms cubic-bezier(0.86, 0, 0.07, 1);
    transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

.buttons-container li:hover::after{
    transform: scale(1,1);
    transform-origin: top left;
}

@media only screen and (max-width: 600px){
    .home .buttons-container ul{
      font-size: 1rem;
      position: relative;
    }
}

@media only screen and (min-width: 2561px){
    .buttons-container ul li{
        font-size: 2.5rem;
    }
}
