.scroll-container {
  width: 100vw;
  height:100vh;
  margin: 0;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
}

.scroll-container {
  overflow-y: auto;
}

.scroll-area {
  margin: 0;
  position: relative;
  height: 100vh;
  scroll-snap-align: start;
}
.scroll-arrow{
  position: absolute;
  right: 6%;
  bottom: 10%;
  color: white;
  border-radius: 50%;
  z-index: 2;
}
.scroll-area:nth-child(3){
  background-color: #02aab0;
  background-image: linear-gradient(135deg,#02aab0,#00cdac);
-webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); 
clip-path: polygon(0 0, 100% 0, 100% 70%, 0% 100%);
}

.scroll-arrow svg{
  font-size: 3rem;
}

@media only screen and (max-width: 1101px) {
  .scroll-area:nth-child(4){
    position: relative;
    height: 150vh;
    background-color: green;
  }
}

@media only screen and (max-width: 780px){
  .scroll-area:nth-child(3){
    background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); 
  }
  .scroll-arrow{
    bottom: 5%;
    right: 5%;
  }
}

@media only screen and (max-width: 445px){
  .scroll-area:nth-child(3){
    background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%); 
  }
  .scroll-arrow{
    bottom: 3.5%;
    right: 5%;
  }
}
.scroll-arrow:hover{
  cursor: pointer;
}

@media only screen and (max-width: 1101px){
  /* .scroll-container{
    scroll-snap-type: none;
  } */
  .scroll-area:nth-child(4){
    height: 180vh;
  }
}
