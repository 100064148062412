.projects{
    display: flex;
    position: relative;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    scroll-snap-align: center;
    scroll-snap-type: y mandatory;
    font-family: 'Nunito';
    overflow: hidden;
}

.projects section{
  font-size: 48px;
}

.project{
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: flex-start; 
}

.information-column{
  display: flex;
  height: 80%;
  min-width: 30%;
  max-width: 50%;
  flex-direction: column;
  align-items: center;
}

.information-column section{
  font-size: 38px;
}

.full-stack-text{
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}
.full-stack-text ul li{
  margin-left: 4px;
}

h4{
  margin: 0 0 0.75rem;
  font-size: 1.25rem;
}

.full-stack-text ul{
  margin: 0 0 0 1rem;
  padding: 0;
}

.image-column{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36.5%;
  gap: 10%;
  height: 80%;
}

.image-column.one{
  margin-top: 3%;
}

.image-column.two{
  justify-content: flex-start;
}

/* .image-column .project-image{
  width: auto;
  max-width: 100%;
  height: auto;
} */


a{
  text-decoration: none;
  color: black;
}

.information-column .buttons-container ul{
  gap: 1rem;
}

.information-column .buttons-container li::after{
    width: 100%;
}

.information-column p{
  text-align: center;
}

.information-column p,
.information-column ul{
    font-size: 1.15rem;

  }

@media only screen and (min-width: 2561px){
  .projects section{
    font-size: 5rem;
  }
  .information-column section{
    font-size: 4rem;
  }
  .information-column p,
  .information-column .full-stack-text,
  .full-stack-text ul{
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 900px){
  .information-column,
  .image-column{
    width: 45%;
  }
}
@media only screen and (max-width: 700px){
  .project{
    margin-top: 2rem;
    align-items: flex-start;
  }
  .information-column p, ul, .full-stack-text ul, .information-column .buttons-container ul{
    font-size: 0.85rem;
  }
}

@media only screen and (max-width: 450px){
  .projects > :first-child,
  .image-column > :nth-child(2){
 display: none; 
  }
  .project{
    width: 95%;
    align-items: center;
    flex-direction: column;
  }
  .information-column, .image-column{
    width: 100%;
    max-width: 100%;
  }
  .project{
    height: 90%;
    margin: 0;
  }
  .project section{
    margin: 1rem;
    font-size: 2rem;
    transform: translateX(-40%);
  }
  .project p{
    margin-top: 0;
  }
  .information-column ul{
    font-size: 1rem;
  }
  .information-column .buttons-container ul{
    margin: 1rem 0 1rem 0;
    font-size: 1rem;
    box-sizing: border-box;
  }
  .information-column .bird-class-title{
    transform: translateX(-40%);
    font-size: 1.35rem;
  }
  .image-column{
    width: 100%;
  }
}
