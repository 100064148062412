.about-container{
    height: 100vh;
    scroll-snap-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 10%;
}

.about-me, .skills{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 2.5rem;
    color: white;
}

.skills-images{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 5%;
}

.about-me{
    width: 30%;
    height: 67%;
}

.about-me p{
    position: relative;
    height: 70%;
    font-size: clamp(1.35rem, 2rem, 1.5vw);
    text-align: center;
}

.skills{
    font-size: 1.5rem;
    width: 50%;
}


.skills-titles{
    display: flex;
    position: relative;
    justify-content: space-evenly;
}

img{
    width: 80px;
    height: 80px;
}
.about h1{
  font-size: 38px;
}

@media only screen and (max-width: 940px){
    img{
        width: 70px;
        height: 70px;
    }
}
@media only screen and (max-width: 700px){
    .about-container{
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;
        margin: 0;
        padding: 0;
    }
    .skills, .about-me{
        width: 100%;
        margin: 0;
        padding: 0;
        top: 0;
    }
    .skills{
        align-items: flex-start;
    }
    h1{
        padding-left: 2rem;
    }

    .about-me{
        width: 100%;
        margin: 0;
        padding: 0;
    }
    img{
        width: 50px;
        height: 50px;
    }

    p{
        padding: 0 1rem 0 1rem;
    }
}

@media only screen and (max-width: 450px){
    .about-container{
        justify-content: flex-end;
        flex-direction: column-reverse;
    }
    img{
        width: 40px;
        height: 40px;
    }
    .about-me{
        font-size: 1rem;
        align-items: flex-start;
        margin-top: 2.5rem;
        height: min-content;
    }
    .about-me h1, .skills h1{
        padding: 0 0 0 16px;
        margin: 0;
    }
    .skills h1{
        font-size: 38px;
    }
    .skills-images{
        gap: 2rem;
    }
}

@media only screen and (min-width: 2561px){
    .about-me{
        font-size: 2rem;
    }
    .skills-images img{
        width: 140px;
        height: 140px;
    }
    h1{
        font-size: 4rem;
    }
}
