.nav{
    margin: 0;
    position: absolute;
    top: 0;
    height: 5.5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 1rem;
    width: 95%;
    z-index: 1;
   }
   
.navbar-list{
  display: flex;
  list-style-type: none;
  gap: 2rem;
}

.navbar-list li{
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
}

.navbar-list li:hover{
  transform: translate(0px, -3px);
  transition: transform 0.5s ease;
}

@media only screen and (max-width: 1100px){
  .nav{
    width: 100%;
    height: 4.5rem;
    z-index: 10;
  }

  .navbar-list{
    margin-right: 1rem;
  }
}

.navbar-list li svg{
  font-size: 3.5rem;
}

.navbar-list li:first-child svg{
  font-size: 4rem;
}

@media only screen and (min-width: 2561px){
  .nav{
    height: 8rem;
    width: 95%;
  }
  .navbar-list li svg{
    font-size: 6rem;
    top: 2rem;
  }
  .navbar-list li:first-child svg{
    font-size: 6.5rem;
  }

}
@media only screen and (max-width: 500px){
  .navbar-list li svg{
    font-size: 3rem;
    top: 2rem;
  }
  .navbar-list li:first-child svg{
    font-size: 3.5rem;
  }

}


