/* .contact-container{
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100vh;
    justify-content: start;
    transform: translateX(20%);
    width: 70%;
    background-color: blue;
    align-items: center;
    scroll-snap-align: center;
}

section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    font-size: 3rem;
    border-bottom: 1px solid black;
} */

.contact{
    height: 100%;
    margin-top: 7rem;
    display: flex;
    justify-content: center;
    scroll-snap-align: center;
    position: relative;
    background-color: #02aab0;
    background-image: linear-gradient(135deg,#02aab0,#00cdac);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%); 
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 80%); 
}

@media only screen and (max-width: 1100px){
    .contact .contact-form{
        margin-top: 15%;
        width: 90%;
    }
}