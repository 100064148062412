.home{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 15%;
}
h2{
    font-size: 3.6rem;
  }

@media only screen and (max-width: 600px){
    h2{
      font-size: 2.8rem;
    }
}

@media only screen and (max-width: 450px){
    .home{
      margin: 5%;
    }
}

@media only screen and (min-width: 2561px){
  h2{
    font-size: 6rem;
  }
}
