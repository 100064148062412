  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 80%;
    height: auto;
    object-fit: cover;
  }

  
  @media only screen and (max-width: 450px){
    .projects .swiper-slide img{
      width: 75%;
    }
  }