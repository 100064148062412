.contact-form{
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5%;
}

.contact-form h2{
    color: white;
    margin-bottom: 5%;
}

form{
    width: 100%;
}

.contact-form input{
    height: 2rem;
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
textarea{ width: 100%; 
margin-top: 0.25rem; 
margin-bottom: 0.25rem; 
height: 8rem; } 


.buttons-container li{ 
z-index: 1; 
border: 2px solid black; 
background-color: white; } 
.buttons-container button{ 
position: relative; 
width: 6rem; 
height: 2.5rem; 
outline: none; 
font-size: 1.2rem; 
background: none; 
border: none; } 


.buttons-container button:hover{ color: white;
    cursor: pointer;
    transition: 0.5s ease all;
}

.message{
  position: relative;
}

.message-sent{
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: center;
  justify-content: space-around; 
  background-color: white;
  width: 100%;
  height: 60%;
  top: 15%;
  padding: 1rem;
  max-height: 30vh;
  z-index: 2;
  height: 100%;
  font-size: 1.5rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.message-sent section{
  width: 90%;
  text-align: center;
}

.message-sent button{
  text-decoration: none;
}

.message-not-sent{
  display: none;
}

.form-control::-webkit-input-placeholder {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
}

.form-control:-ms-input-placeholder {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
}

.form-control:-moz-placeholder {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
}

.form-control::-moz-placeholder {
  font-family: 'Nunito', Arial, Helvetica, sans-serif;
}
